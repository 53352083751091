import React, {useContext, useEffect, useState} from 'react';
import {apiSignup} from '../api';
import {AuthContext} from './AuthProvider';

interface LoginModalProps {
    open: boolean;
    handleClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({open, handleClose}) => {
    const {loggedIn, login, logout} = useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [isLogin, setIsLogin] = useState(true);

    const handleLogin = async () => {
        if (!login) return;
        await login(email, password);
    };

    const handleSignup = async () => {
        if (!login) return;
        await apiSignup(
            name,
            email,
            password);
        await login(email, password);
    };

    useEffect(() => {
        if (loggedIn) handleClose();
    }, [loggedIn]);

    const renderLoginModal = () => (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4 text-[#a700f5]">Login</h2>
                <div className="flex flex-col space-y-4">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border border-gray-300 p-2 rounded"
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="border border-gray-300 p-2 rounded"
                    />
                </div>
                <div className="mt-4 flex justify-between">
                    <button
                        onClick={() => setIsLogin(!isLogin)}
                        className="text-[#a700f5] hover:underline"
                    >
                        Switch to Signup
                    </button>
                    <div className="space-x-2">
                        <button
                            onClick={handleLogin}
                            className="bg-[#a700f5] text-white py-2 px-4 rounded hover:bg-blue-700"
                        >
                            Login
                        </button>
                        <button
                            onClick={handleClose}
                            className="bg-[#BB85FF] text-white py-2 px-4 rounded hover:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderSignupModal = () => (

        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4 text-[#a700f5]">Sign Up</h2>
                <div className="flex flex-col space-y-4">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="border border-gray-300 p-2 rounded"
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border border-gray-300 p-2 rounded"
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="border border-gray-300 p-2 rounded"
                    />
                </div>
                <div className="mt-4 flex justify-between">
                    <button
                        onClick={() => setIsLogin(!isLogin)}
                        className="text-[#a700f5] hover:underline"
                    >
                        Switch to Login
                    </button>
                    <div className="space-x-2">
                        <button
                            onClick={handleSignup}
                            className="bg-[#a700f5] text-white py-2 px-4 rounded hover:bg-blue-700"
                        >
                            Signup
                        </button>
                        <button
                            onClick={handleClose}
                            className="bg-[#BB85FF] text-white py-2 px-4 rounded hover:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
    if (!open) return null;
    return isLogin ? renderLoginModal() : renderSignupModal();
};

export default LoginModal;

