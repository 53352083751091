import axios from 'axios';
import {ChatCompletionRequest, TandemNode, TandemType, TandemTypeDetails, TandemTypeField, User} from "./types";

const BASE_URL = 'https://api.neuronotify.com/v1';


export enum SearchRequestDataQueryType {
    equals = 'equals',
    not_equals = 'not_equals',
    contains = 'contains',
    greater_than = 'greater_than',
    less_than = 'less_than',
    is_null = 'is_null',
}

export type Where = {
    key: string,
    value: string,
    queryType: SearchRequestDataQueryType
}

export enum SortDirection {
    ascending = 'ascending',
    descending = 'descending'
}

export type Sort = {
    field: string,
    direction: SortDirection
}

export type SearchRequestBody = {
    where?: Where[],
    offset: number,
    limit: number,
    sort?: Sort[]
}

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwtToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

export const createTandemType = async (name: string, userId: string): Promise<TandemType> => {
    const response = await axios.post(`${BASE_URL}/types`, {name, userId});
    return response.data;
};

export const getTandemTypeDetails = async (typeId: string): Promise<TandemTypeDetails> => {
        // const response = await axios.get(`${BASE_URL}/types/${typeId}`);
        // return response.data;

        const tandemTypePromise = getNodeById('00000000-0000-0000-0000-000000000000', typeId);
        const tandemTypeFieldsPromise = searchNodes('11111111-1111-1111-1111-111111111111', {
            where: [{
                key: 'tandem_type_id',
                value: typeId,
                queryType: SearchRequestDataQueryType.equals
            }],
            offset: 0,
            limit: 100,
            sort: [{
                field: 'created_at',
                direction: SortDirection.ascending
            }]
        });
        const [tandemType, tandemTypeFields] = await Promise.all([tandemTypePromise, tandemTypeFieldsPromise]);
        return {tandemType: tandemType as TandemType, tandemTypeFields: tandemTypeFields as TandemTypeField[]};
    };

export const addNewNode = async (typeId: string, node: Record<string, unknown>): Promise<TandemNode> => {
    const response = await axios.post(`${BASE_URL}/node/${typeId}`, node);
    return response.data;
};

export const getNodeById = async (typeId: string, nodeId: string): Promise<TandemNode> => {
    const response = await axios.get(`${BASE_URL}/node/${typeId}/${nodeId}`);
    return response.data;
};

export const updateNode = async (typeId: string, nodeId: string, node: Record<string, unknown>): Promise<TandemNode> => {
    const response = await axios.patch(`${BASE_URL}/node/${typeId}/${nodeId}`, node);
    return response.data;
}

export const deleteNode = async (typeId: string, nodeId: string) => {
    const response = await axios.delete(`${BASE_URL}/node/${typeId}/${nodeId}`);
}

export const searchNodes = async (typeId: string, searchParams: SearchRequestBody): Promise<TandemNode[]> => {
    const response = await axios.post(`${BASE_URL}/search/${typeId}`, searchParams);
    return response.data;
};

// User routes
export const createUser = async (name: string, email: string): Promise<Record<string, User>> => {
    const response = await axios.post(`${BASE_URL}/users`, {name, email});
    return response.data;
};

export const getUserById = async (userId: string): Promise<Record<string, User>> => {
    const response = await axios.get(`${BASE_URL}/users/${userId}`);
    return response.data;
};

export const apiSignup = async (name: string, email: string, password: string): Promise<User> => {
    const response = await axios.post(`${BASE_URL}/users`, {name, email, password});
    return response.data;
};

export const apiLogin = async (email: string, password: string): Promise<{ token: string, user: User }> => {
    const response = await axios.post(`${BASE_URL}/login`, {email, password});
    if (response && response.data.token) {
        localStorage.setItem('jwtToken', response.data.token);
    }
    return response.data;
};

export const apiOpenAi = async (request: ChatCompletionRequest): Promise<string> => {
    const response = await axios.post(`${BASE_URL}/openai`, request);
    return response.data;
}

export const getBlobDownloadUrl = (blobId: string): string => {
    return `${BASE_URL}/blob/${blobId}?token=${localStorage.getItem('jwtToken')}`
}

export const deleteAccount = async () => {
    await axios.delete(`${BASE_URL}/users/delete-account`);
    localStorage.removeItem('jwtToken');
}