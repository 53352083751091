import { Container } from "postcss";
import PrivacyPolicy from "./privacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <div className="container">
      <article className="prose lg:prose-xl prose-slate mx-auto max-w-2xl">
      {/* Import PrivacyPolicy because long text slows down VSCode */}
      <PrivacyPolicy />
      </article>
    </div>
  );
}

export default PrivacyPolicyPage