// src/App.tsx

import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./pages/home/HomePage";
import Workspace from './pages/workspace/Workspace';
import { AuthProvider } from './components/AuthProvider';
import 'tailwindcss/tailwind.css';
import DetailPage from "./pages/detal/DetailPage";
import ListPage from "./pages/list/ListPage";
import TasksPage from "./pages/tasks/TasksPage";
import TasksDetailPage from "./pages/tasks/TaskDetailPage";
import TaskEditPage from "./pages/tasks/TaskEditPage";
import TaskAddPage from "./pages/tasks/TaskAddPage";
import PrivacyPolicyPage from "./pages/privacyPolicy/privacyPolicyPage";
import SettingsPage from "./pages/settings/SettingsPage";
import TeamPage from "./pages/team/TeamPage";
import TermsOfServicePage from "./pages/termsOfService/TermsOfServicePage";
import TopNav from './components/TopNav';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <TopNav />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/workspace/:workspaceId" element={<Workspace />} />
          <Route path="/detail/:typeId" element={<ListPage />} />
          <Route path="/detail/:typeId/:id" element={<DetailPage />} />
          <Route path="/tasks" element={<TasksPage />} />
          <Route path="/tasks/:taskId" element={<TasksDetailPage />} />
          <Route path="/tasks/:taskId/edit" element={<TaskEditPage />} />
          <Route path="/tasks/add" element={<TaskAddPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        </Routes>
      </Router>
      <Footer />
    </AuthProvider>
  );
};

export default App;

