import { LinkedinIcon } from "./LinkedinIcon";

export interface TeamMemberProps {
  name: string;
  title: string;
  description: string;
  imgSrc: string;
  linkedinUrl?: string;
}

export function TeamMember({
  name,
  title,
  description,
  imgSrc,
  linkedinUrl = "",
}: TeamMemberProps) {
  return (
    <div className="flex flex-col items-center space-y-4 text-center">
      <div className="relative h-32 w-32">
        <img
          src={imgSrc}
          alt={name}
          className="h-full w-full rounded-full object-cover"
          width={128}
          height={128}
        />
        {linkedinUrl !== "" && (
          <div className="absolute bottom-0 right-0 h-8 w-8 rounded-full hover:bg-[#0077B5] text-gray-50  bg-slate-900 hover:text-grey-50 flex items-center justify-center">
            <a href={linkedinUrl} target="_blank">
              <LinkedinIcon className="h-5 w-5" />
            </a>
          </div>
        )}
      </div>
      <div className="space-y-1">
        <h3 className="text-lg font-bold">{name}</h3>
        <p className="text-gray-500 dark:text-gray-400">{title}</p>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {description}
        </p>
      </div>
    </div>
  );
}
