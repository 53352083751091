import React from 'react';
import LogoSvg from '../svgs/logoSvg';

// Step 1: Define the type for the value
interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
}

// Step 2: Create the object with key/value pairs
const navigation: Record<string, NavigationItem> = {
  Home: { name: 'Home', href: '/', current: false },
  Team: { name: 'Team', href: '/team', current: false },
  PrivacyPolicy: { name: 'Privacy Policy', href: '/privacy-policy', current: false },
  TermsOfService: { name: 'Terms of Service', href: '/terms-of-service', current: false },
};

// Step 3: Order the keys
const navigationOrder: string[] = [
  // 'Home', 
  'Team',
  'PrivacyPolicy',
  'TermsOfService'
];

// Step 4: Loop and render (React component example)

const Footer: React.FC = () => {
  return (
    <footer className="bg-slate-50 border-t border-grey-400 mt-16 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className='flex justify-center items-center'>
          <a href="/" className="flex justify-center items-center">
            <LogoSvg className="mx-auto h-20 w-auto" />
          </a>
          <h2 className="text-5xl font-black text-[#a700f5] pl-2">NeuroNotify</h2>
        </div>

        <div className="py-2">
          <nav className="mt-10 " aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              {navigationOrder.map((key) => {
                const item = navigation[key];
                return (
                  <a
                    key={key}
                    href={item.href}
                    className="inline-block rounded-lg px-2 py-1 text-md font-semibold text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                  >
                    {item.name}
                  </a>
                )
              })}
            </div>
          </nav>
        </div>
        <div className="flex justify-center items-center border-t border-slate-400/10 py-10 ">
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">Copyright © 2024 NeuroNotify. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 