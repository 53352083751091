import { Fragment, useContext, useState } from 'react'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import LogoSvg from '../svgs/logoSvg'
import { AuthContext } from './AuthProvider'
import LoginModal from './LoginModal'

/**
 * Top Navbar Links 
 */

interface TopNavItem {
  name: string
  current: boolean
  href?: string
  onClick?: () => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const NavLink = ({ item }: { item: TopNavItem }): JSX.Element => {
  return (
    <DisclosureButton
      key={item.name}
      as='a'
      href={item.href || '#'}
      onClick={item.onClick}
      className={classNames(
        item.current
          ? 'bg-[#a700f5] text-white'
          : 'text-[#a700f5] hover:bg-[#a700f5] hover:text-white',
        'px-3 py-2 rounded-md text-lg font-bold'
      )}
      aria-current={item.current ? 'page' : undefined}
    >
      {item.name}
    </DisclosureButton>
  )
}

/**
 * Renders a top navigation bar with a mobile menu button, logo, navigation links, and a profile dropdown.
 *
 * @return {JSX.Element} The top navigation bar component.
 */
export default function TopNav(): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const { loggedIn, login, logout } = useContext(AuthContext);

  const openModal = () => {
    setShowModal(true);
  };

  const callLogout = () => {
    logout?.();
  };




  const Nav: Record<string, TopNavItem> = {
    Login: { name: 'Login', onClick: openModal, current: false },
    Signup: { name: 'Signup', onClick: openModal, current: false },
    Logout: { name: 'Logout', onClick: callLogout, current: false },
  }


  return (
    <>
      {showModal && (
        <LoginModal open={showModal} handleClose={() => setShowModal(false)} />
      )}
      <Disclosure as="nav" className="bg-white sticky top-0 z-10 border-b-violet-50">
        {({ open }: { open: boolean }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-[#BB85FF] hover:bg-[#a700f5] hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="/"><LogoSvg color="#a700f5" className="w-10 h-10" /></a>
                    <h1 className="text-3xl font-black text-[#a700f5] pl-2 mr-5"><a href="/">NeuroNotify</a></h1>
                  </div>

                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Nav links */}
                  <div id='nav-links' className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {!loggedIn ? (
                        <>
                          <NavLink item={Nav.Login} />
                          <NavLink item={Nav.Signup} />
                        </>
                      ) : (
                        <>
                          <NavLink item={Nav.Logout} />
                        </>
                      )}
                    </div>
                  </div> {/* end nav-links */}
                </div>
              </div>
            </div>

            <DisclosurePanel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {!loggedIn ? (
                  <>
                    <NavLink item={Nav.Login} />
                    <NavLink item={Nav.Signup} />
                  </>
                ) : (
                  <>
                    <NavLink item={Nav.Logout} />
                  </>
                )}
                {/* {Nav.map((item) => (
                  <DisclosureButton
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-[#BB85FF] text-white' : 'text-[#BB85FF] hover:bg-[#a700f5] hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-bold'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </DisclosureButton>
                ))} */}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </>
  )
}
