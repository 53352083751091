import React from "react";
import { TeamMember, TeamMemberProps } from "../../components/TeamMember";

const Team: Record<string, TeamMemberProps> = {
  Nathan: {
    name: "Nathan Burnham",
    title: "Founder & CEO",
    description:
      "Nathan developed object detection software at Flock Safety, contributing to solving 10% of crime in the US. As the first employee and Tech Lead at Arena Club, he drove the company to product market fit with innovative machine learning systems. He also founded the Mercer Robotics Lab, fostering student robotics projects.",
    imgSrc: "/teamPhotos/NathanBurnham.jpg",
    linkedinUrl: "https://www.linkedin.com/in/nburn42/",
  },
  Jorge: {
    name: "Jorge Ceja",
    title: "Founder & CTO",
    description:
      "Jorge developed generative AI for 3D assets at MetaMetaverse and applied transformer models to financial forecasting. He mentored at Udacity and has extensive freelance experience in deep learning and computer vision. Notably, he led a submersible robotics team, creating a fault-tolerant system with off-the-shelf hardware.",
    imgSrc: "/teamPhotos/JorgeCeja.jpg",
    linkedinUrl: "https://www.linkedin.com/in/jorge-ceja/",
  },
  Emmanuel: {
    name: "Emmanuel Mwangi",
    title: "Director of Engineering",
    description:
      "Emmanuel Mwangi has led teams and initiatives at Endpoint Closing, Swiftly, and Tinder, managing app releases to millions of users. He founded an AI startup, building a cost-effective ML pipeline from bare-metal servers. Notably, he hacked a traffic light system as a kid, sparking his curiosity in engineering.",
    imgSrc: "/teamPhotos/EmmanuelMwangi.jpg",
    linkedinUrl: "https://www.linkedin.com/in/emmanuelm",
  },
  Lucy: {
    name: "Lucy Chen",
    title: "Founder & COO",
    description:
      "Lucy Chen has experience in operations and strategic support for several startups, including Aves Lair. At Aves Lair, she led initiatives in business development, talent acquisition, and financial team support. She also successfully traded a Tupperware box for an all-expense-paid spa day as part of an entrepreneurship class challenge.",
    imgSrc: "/teamPhotos/LucyChen.jpg",
    linkedinUrl: "https://www.linkedin.com/in/fiatlucy/",
  },
  Sam: {
    name: "Sam Hashimi",
    title: "Lead ML Engineer",
    description:
      "Sam Hashimi, a Senior Software Engineer, developed backend systems and AI pipelines at Course Hero, Dave, and Beautycounter. He worked on the SpaceX Hyperloop prototype and created a proprietary web application at IBM. Notably, he built an immutable Promo Engine for an e-commerce site, reducing Time to First Paint and handling seven-digit sales monthly.",
    imgSrc: "/teamPhotos/SamHashimi.jpg",
    linkedinUrl: "https://www.linkedin.com/in/sam-hashimi-81b2239b/",
  },
  Nick: {
    name: "Nick Ernst",
    title: "Android Engineer",
    description:
      "Nick is an Android Developer at NeuroNotify, using Kotlin and Google Cloud CI/CD to create dynamic AI interfaces. With expertise in Linux, server/network administration, and data science, he focuses on innovative communication methods. Nick founded the LA Rationality group and organized a BIL Conference on Emerging Technology and Global Inequality.",
    imgSrc: "/teamPhotos/NickErnst.jpg",
    linkedinUrl: "www.linkedin.com/in/nickernst",
  },
};

/**
 * List of Team Members for how they will appear in the Team Page
 */
const TeamList: TeamMemberProps[] = [
  Team.Nathan,
  Team.Jorge,
  Team.Lucy,
  Team.Emmanuel,
  Team.Sam,
  Team.Nick,
];

export default function Component() {
  return (
    <section className="container pt-12 md:pt-24 lg:pt-32 mx-auto flex flex-col items-center">
      <div className="space-y-10 xl:space-y-16 px-4 md:px-6">
        <div className="flex flex-col items-center space-y-4 text-center">
          <div className="space-y-2">
            <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
              Meet the Team
            </h1>
            <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
              We are a passionate group of individuals dedicated to building
              solutions for our users.
            </p>
          </div>
        </div>
        <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {TeamList.map((member) => (
            <TeamMember
              key={member.name}
              name={member.name}
              title={member.title}
              description={member.description}
              imgSrc={member.imgSrc}
              linkedinUrl={member.linkedinUrl}
            />
          ))}
          {/* <TeamMember
                name="Nathan Burnham"
                title="Co-Founder & CEO"
                description="John is a seasoned entrepreneur with a passion for building disruptive technologies. He leads the team with his strategic vision and unwavering commitment to excellence."
                imgSrc="/placeholder.svg"
              />
              <TeamMember
                name="Jorge Cera"
                title="Co-Founder & CTO"
                description="Jane is a brilliant engineer with a track record of delivering cutting-edge technology solutions. She leads the technical team and ensures the seamless execution of our product roadmap."
                imgSrc="/placeholder.svg"
              />
              <TeamMember
                name="Lucy Chen"
                title="Co-Founder & Head of Operations"
                description="Michael is a product visionary with a keen eye for user experience. He leads the product team in crafting innovative solutions that delight our customers."
                imgSrc="/placeholder.svg"
              />

              <TeamMember
                name="Emmanuel Mwangi"
                title="Head of Marketing"
                description="Emily is a marketing maven with a deep understanding of our target audience. She leads the marketing team in crafting compelling campaigns that drive growth and brand awareness."
                imgSrc="/placeholder.svg"
              /> */}
        </div>
      </div>
    </section>
  );
}
