import React, { useState, useContext } from 'react';
import '../../App.css';
import LoginModal from '../../components/LoginModal';
import { AuthContext } from '../../components/AuthProvider';
import phone from "../../assets/Phone.svg";
import gplay from "../../assets/googleplay.svg";
import phoneMockup from "../../assets/Phone Mockup.svg";
import LogoSvg from "../../svgs/logoSvg";


const HomePage: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showModal && (
                        <LoginModal open={showModal} handleClose={() => setShowModal(false)} />
                    )}
            <div className="bg-gradient-to-b from-[#6B5F88] to-[#1B1822] w-full h-full lg:h-[1024px]">

                {/* hero */}
                <div className="bg-center bg-pattern bg-cover bg-no-repeat h-full w-full pt-36">
                    <h1
                        className="w-5/6 mx-auto text-center mb-10 text-[48px]/[80px] lg:text-[100px]/[130px] xl:text-[128px]/[160px]
          font-black text-white top-5"
                    >
                        Smart <span className="text-[#BB85FF]">AI Notifications</span> You
                        Never Miss
                    </h1>
                    <p
                        className="text-white text-[20]/[44px]
           lg:text-[40px]/[60px] max-lg:py-6 lg:my-24 w-5/6 mx-auto text-center font-light"
                    >
                        <br />
                        The app that brings information to you in a <br />{" "}
                        <span className="text-[#BB85FF] font-extrabold">
              digestible
            </span>{" "}
                        and{" "}
                        <span className="text-[#BB85FF] font-extrabold"> unavoidable </span>
                        format.{" "}
                    </p>
                </div>
            </div>

            {/* cta section */}
            <div className="flex flex-col md:flex-row w-full max-md:items-center">
                <div className="w-full md:w-[45%] flex justify-center">
                    <img src={phone} alt="phone" />
                </div>
                <div className="w-full md:w-[55%] flex flex-col items-center justify-center">
                    <div className="flex gap-4 items-center mb-4">
                        <LogoSvg width="100" height="100" />
                        <h2 className="text-[#a700f5] text-4xl md:text-6xl font-black">
                            NeuroNotify
                        </h2>
                    </div>
                    <h3 className="text-center text-[#434343] font-bold text-4xl md:text-5xl">
                        Download our mobile app!{" "}
                    </h3>
                    <img
                        src={gplay}
                        alt="downloadbtn"
                        className="mt-10 w-[220px] h-[60px] max-md:mb-20"
                    />
                </div>
            </div>
            {/* timeline section */}
            <div className="bg-[#3C3C3C] px-5 xl:px-20 pb-24 overflow-hidden">
                <div className="flex flex-col xl:flex-row">
                    <div className="w-full xl:w-[50%]">
                        {/* section 1 */}
                        <div className="-mt-40 w-full">
                            <img
                                src={phoneMockup}
                                alt="Phone"
                                className="mb-5 max-xl:mx-auto"
                            />
                            <p className="w-full xl:w-5/6 text-white text-[24px]/[44px] xl:text-[40px]/[60px] font-light max-xl:text-center max-xl:mx-5">
                                {" "}
                                What do you want to know? What do you want to remember? Receive
                                any
                                <span className="text-[#BB85FF] font-extrabold">
                  {" "}
                                    digestible information you want{" "}
                </span>
                                delivered to you.
                            </p>
                        </div>
                        <div className="mt-28 max-xl:mx-auto">
                            <img
                                src={phoneMockup}
                                alt="Phone"
                                className="mb-28 max-xl:mx-auto"
                            />
                            <p className="w-full xl:w-5/6 text-white text-[24px]/[44px] xl:text-[40px]/[60px] font-light max-xl:text-center max-xl:mx-5">
                                {" "}
                                Option to generate a
                                <span className="text-[#BB85FF] font-extrabold">
                  {" "}
                                    document output{" "}
                </span>
                                Perfect for accomplishing light automated tasks!
                            </p>
                        </div>
                        <div className="mt-28 max-xl:mx-auto">
                            <img
                                src={phoneMockup}
                                alt="Phone"
                                className="mb-28 max-xl:mx-auto"
                            />
                        </div>
                    </div>

                    {/* dashed line */}
                    <div className="hidden xl:block">
                        <div className="relative border-l border-white border-dashed h-[3062px] mt-[230px]">
                            {/* circle */}
                            <div className="w-[50px] h-[50px] rounded-full border-[5px] border-white bg-[#BB85FF] absolute top-0 -left-6 "></div>
                            <div className="w-[50px] h-[50px] rounded-full border-[5px] border-white bg-[#BB85FF] absolute top-[530px] -left-6 "></div>
                            <div className="w-[50px] h-[50px] rounded-full border-[5px] border-white bg-[#BB85FF] absolute top-[1080px] -left-6 "></div>
                            <div className="w-[50px] h-[50px] rounded-full border-[5px] border-white bg-[#BB85FF] absolute top-[1770px] -left-6 "></div>
                            <div className="w-[50px] h-[50px] rounded-full border-[5px] border-white bg-[#BB85FF] absolute top-[2400px] -left-6 "></div>
                        </div>
                    </div>

                    <div className="w-full xl:w-[50%] xl:pl-32">
                        <div className="xl:mt-[230px] w-full">
                            <p className="text-white xl:text-[40px]/[60px] font-light mb-8 text-[24px]/[44px]  max-xl:text-center max-xl:mx-5">
                <span className="text-[#BB85FF] font-extrabold">
                  Attention grabbing{" "}
                </span>
                                with our dynamic AI generated notifications
                            </p>
                            <img
                                src={phoneMockup}
                                alt="Phone"
                                className="mb-8 max-xl:mx-auto"
                            />
                            <p className="text-white xl:text-[40px]/[60px] font-light mb-8 text-[24px]/[44px]  max-xl:text-center max-xl:mx-5">
                                Create notifications as simple or as sophisticated as you want
                                with infinite
                                <span className="text-[#BB85FF] font-extrabold">
                  {" "}
                                    possible layers of tasks and sub tasks
                </span>
                            </p>
                        </div>
                        <div className="mt-28">
                            <img
                                src={phoneMockup}
                                alt="Phone"
                                className="mb-28 max-xl:mx-auto"
                            />
                            <p className="text-white xl:text-[40px]/[60px] font-light mb-8 text-[24px]/[44px]  max-xl:text-center max-xl:mx-5 ">
                                Option to allow
                                <span className="text-[#BB85FF] font-extrabold">
                  {" "}
                                    notification overlays{" "}
                </span>
                                and never miss a thing
                            </p>
                        </div>
                    </div>
                </div>
                <div className="xl:w-1/2 mx-auto  border border-[#BB85FF] py-8 text-center text-3xl xl:text-5xl font-bold text-[#BB85FF] rounded-md">
                    Download our mobile app!
                </div>
            </div>
        </>

    );
};

export default HomePage;


